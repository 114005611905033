<template>
    <div class="chart-div">
        <div class="chart-left">
            <div class="top">
                <Card class="item">
                    <div class="title">{{ tableData.openCompanyCount || 0 }}/{{ tableData.companyCount || 0 }}</div>
                    <div class="title">&nbsp;</div>
                    <div class="title">开业中店铺/企业总数</div>
                </Card>
                <!-- <Card class="item">
                    <div class="title">{{tableData.total || 0}}</div>
                    <div class="title">&nbsp;</div>
                    <div class="title">社区用户</div>
            </Card>     -->
            </div>
            <div class="center">
                <div style="font-weight:bold;text-align:center">单位入驻变化统计图</div>
                <VeLine style="margin-top:10px;" v-if="showLine" :data="userStatData"></VeLine>
            </div>
            <div class="bottom" v-if="tableList.length != 0">
                <Table border stripe :columns="talbeColumns" :data="tableList"></Table>
            </div>
        </div>
        <!-- <div class="chart-right"></div> -->
    </div>
</template>

<script>
import VeLine from "v-charts/lib/line.common"
export default {
    components: { VeLine },
    props: {
        isShow: {
            type: Number,
            default: () => {
                return 0
            },
        },
    },
    data() {
        return {
            tableData: [],
            showLine: true,
            userStatData: {
                columns: ["时间", "新增开业单位", "停业单位", "单位数量增减变化"],
                rows: [],
            },
            tableList: [],
            talbeColumns: [
                // {
                //     title:'开业中排名',
                //     key:'',
                //     align:'center',
                //     minWidth:80
                // },
                {
                    title: "行业分类",
                    key: "industryCode",
                    align: "center",
                    minWidth: 80,
                    render: (h, params) => {
                        return h("div", {}, params.row.industryCode == [] ? "" : params.row.industryCode)
                    },
                },
                {
                    title: "开业中已定位",
                    key: "targeted",
                    align: "center",
                    minWidth: 80,
                },
                {
                    title: "开业中数量",
                    key: "settle",
                    align: "center",
                    minWidth: 80,
                },
            ],
            // 行业类型
            industryList: [],
        }
    },
    methods: {
        // 获取可视化数据的接口
        getChart() {
            this.$get("/gateway/api/sycompany/pc/company/visualizationStatistics").then(res => {
                if (res.code == 200) {
                    this.tableData = res.data
                    this.showLine = false
                    this.$nextTick(() => {
                        this.showLine = true
                    })
                    this.userStatData.rows = []
                    this.tableList = []
                    if (this.tableData.settledStatisticsRos && this.tableData.settledStatisticsRos.length != 0) {
                        this.tableData.settledStatisticsRos.map(item => {
                            this.userStatData.rows.push({ 时间: item.statisticsTime, 新增开业单位: item.settle, 停业单位: item.closed, 单位数量增减变化: Number(item.increaseOrDecrease) })
                        })
                    }
                    if (this.tableData.industryStatisticsRos && this.tableData.industryStatisticsRos.length != 0) {
                        this.tableList = this.tableData.industryStatisticsRos
                        if (this.tableList.length != 0) {
                            this.tableList.map(item => {
                                let data = []
                                if (item.industryCode && item.industryCode.length != 0) {
                                    // item.industryCode = item.industryCode.join(',')
                                    this.industryList.map(items => {
                                        if (item.industryCode.indexOf(items.dictKey) > -1) {
                                            data.push(items.dictValue)
                                        }
                                    })
                                    item.industryCode = data.join(",")
                                }
                            })
                        }
                    }
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        getIndustry(type, model) {
            this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType: type,
            }).then(res => {
                if (res.code == 200) {
                    this[model] = res.dataList
                }
            })
        },
    },
    watch: {
        isShow: {
            handler(val) {
                if (val) {
                    this.getIndustry("INDUSTRY_TYPE", "industryList")
                    this.getChart()
                }
            },
        },
    },
}
</script>

<style lang="less" scoped>
.chart-div {
    width: 100%;
    display: flex;
    height: 790px;
    .chart-left {
        flex: 1;
        height: 100%;
        .top {
            display: flex;
            justify-content: center;
            .item {
                width: 20%;
                text-align: center;
                margin-right: 20px;
            }
        }
        .center {
            margin: 20px 0;
            height: 320px;
        }
        .bottom {
            margin: 20px auto;
            // height: 320px;
            max-width: 1400px;
            margin-top: 80px;
            margin-bottom: 80px;
        }
    }
    .chart-right {
        width: 60%;
        height: 100%;
    }
}
/deep/.ivu-table-wrapper {
    height: 200px;
    overflow-x: hidden;
}
/deep/.ivu-table {
    overflow-y: scroll !important;
}
</style>
